import { ReactSortable } from "react-sortablejs";
import returnIcon from "../../../../assets/img/modals/return.svg";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, showLoader } from "../../../loader";
import { apiUrl } from "../../../../utils/url";
import FormFieldError from "../../../errors/formFieldError";
import tickIconPrimary from "../../../../assets/img/white-tick-icon.svg";
import ErrorMessage from "../../../errors/errorMessage";
import CroppieModal from "../../../croppie-modal";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import timezones from "../../timezone.json";
import calendarIcon from "../../../../assets/img/products/calendar-icon.svg";
import removeicon from "../../../../assets/img/products/removeicon.svg";
import digitalFileIcon from "../../../../assets/img/products/digitalfileicon.svg";
import visitIcon from "../../../../assets/img/products/visiticon.svg";
import imageIcon from "../../../../assets/img/image-icon.svg";
import globeIcon from "../../../../assets/img/globe.svg";
import chevron_down from "../../../../assets/img/modals/chevron-down.svg";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import dustbinWhite from "../../../../assets/img/products/dustbin-white.svg";
import googlemetIcon from "../../../../assets/img/products/googlemeticon.svg";
import customIcon from "../../../../assets/img/products/customIcon.svg";
import questionIcon from "../../../../assets/img/products/questionicon.svg";
import searchicon from "../../../../assets/img/products/searchbar.svg"
import ConnectCalendarModal from "../../../connect-calendar-modal";
import addFaqIcon from "../../../../assets/img/products/add-faq-btn.svg";
import zoomIcon from "../../../../assets/img/modals/zoom-icon.svg";

const ContentPage = ({ data , handleSubmit , error, setError  }) => {
    // console.log("data",data)
    const [content, setContent] = useState(data?.content);
    const [openCalendar, setOpenCalendar] = useState(data?.content == 'calender' ? true : false);
    const [opendigitalfile, setOpendigitalfile] = useState(data?.content == 'files' ? true : false);
    const [duration, setDuration] = useState(data?.calendar[0]?.duration ?? 15);
    const [book_within_next_day, setBook_Within_Next_Day] = useState(data?.calendar[0]?.book_within_next_day ?? 7);
    const [custom_meeting_link, setCustomMeetingLink] = useState(data?.calendar[0]?.custom_meet_link);
    const [timezone, setTimezone] = useState(data?.calendar[0]?.timezone);
    const [meeting_location, setMeetingLocation] = useState(data?.calendar[0]?.meeting_location);
    const [openVisitUrl, setOpenVisitUrl] = useState(data?.content == 'url' ? true : false);
    const [urlPop, setUrlPop] = useState(true)
    const [selectedTimezone, setSelectedTimezone] = useState(data?.calendar[0]?.timezone);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [removepop, setRemoveopop] = useState(false);
    const [content_file, setContent_File] = useState();
    const [contentText, setContentText] = useState(data?.content_file_name)
    const [content_url, setContent_Url] = useState(data?.content_url);
    const [days, setDays] = useState("Mon");
    const history  = useHistory();
    const { slug, basePath } = useSelector((state) => state?.slugReducer);
    const { meet_token, zoom_token } = useSelector((state) => state?.meetConnectedReducer);
    const [showConnectCalendarModal, setShowConnectCalendarModal] = useState(false);
    const [isMeetConnected, setIsMeetConnected] = useState({google_meet:meet_token ? true :false,zoom:zoom_token?true:false});
    const [searchInput, setSearchInput] = useState("");
    const getDayName = (dayIndex) => {
      const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      return days[dayIndex];
    };
    const numberOfSlots = data?.content == 'calender' && data?.availabilities_days ? data?.availabilities_days.length : 8;
    const [defaultTimeSlots, setDefaultTimeSlots] = useState(
      Array.from({ length: 7 }, (_, dayIndex) => ({
          id: dayIndex,
          day: getDayName(dayIndex),
          slots: Array.from({ length: numberOfSlots }, (_, slotIndex) => ({
              id: `${dayIndex}-${slotIndex}`,
              time: 9 + slotIndex,
          })),
          showDropdown: false, // New property
      }))
  );
  const updateStateWithAvailabilities = (availabilities) => {
    const updatedTimeSlots = defaultTimeSlots.map((daySlot) => {
      const dayAvailabilities = availabilities.filter(
        (availability) => availability.days.toLowerCase() === daySlot.day.toLowerCase()
      );
    
      if (dayAvailabilities.length > 0) {
        return {
          ...daySlot,
          slots: daySlot.slots.map((slot, slotIndex) => {
            if (dayAvailabilities[slotIndex]) {
              return {
                ...slot,
                time: dayAvailabilities[slotIndex].from,
              };
            } else {
              return {
                ...slot,
                time: false,
              };
            }
          }),
        };
      } else {
        return {
          ...daySlot,
          slots: daySlot.slots.map((slot) => ({
            ...slot,
            time: false,
          })),
        };
      }
    });
    setDefaultTimeSlots(updatedTimeSlots);
    };
  useEffect(() => {
    if (data?.availabilities_days) {
      updateStateWithAvailabilities(data?.availabilities_days);
    }
  }, [data?.availabilities_days]);
    function formatTime(time) {
        const formattedTime = String(time);
        
        if (formattedTime.includes(':')) {
            return formattedTime;
        } else {
            return formattedTime.padStart(2, '0') + ':00';
        }
    }
    // const handleSearchInputChange = (event) => {
    //   setSearchInput(event.target.value);
    // };
    function handleSearchInputChange() {
      const searchText = document.querySelector('.timezone-search-bar').value.toLowerCase();
      const timezoneOptions = document.querySelectorAll('.timezone-option');
      timezoneOptions.forEach(option => {
          const optionText = option.textContent.toLowerCase();
          if (optionText.includes(searchText)) {
              option.style.display = 'flex';
          } else {
              option.style.display = 'none';
          }
      });
  }
    const filteredTimezones = timezones.filter((timezone) =>
      timezone.city.toLowerCase().includes(searchInput.toLowerCase())
    );
    const handleSelectTimezone = (timezone) => {
      setSelectedTimezone(timezone);
      setTimezone(timezone.city + ' GMT' + timezone.gmtOffset);
      setDropdownOpen(false);
    };

    function handleCalendar() {
        setContent("calender");
        setOpendigitalfile(false);
        setOpenCalendar(true);
        setOpenVisitUrl(false);
    }
    function handldigital() {
    setContent("files");

    setOpendigitalfile(true);
    setOpenCalendar(false);
    setOpenVisitUrl(false);
    }
    function handlevisiturl() {

    setContent("url");
    setOpenVisitUrl(true);
    setOpenCalendar(false);
    setOpendigitalfile(false);
    }
    const handleDeleteTimeSlot = (dayId, slotId) => {
    const updatedTimeSlots = defaultTimeSlots.map((day) => {
        if (day.id === dayId) {
            const updatedSlots = day.slots.filter((slot) => slot.id !== slotId);
            return { ...day, slots: updatedSlots, showDropdown: false };
        }
        return day;
    });

    setDefaultTimeSlots(updatedTimeSlots);
    };
    const handleNewAddTimeSlot = (dayId) => {
    const updatedTimeSlots = defaultTimeSlots.map((day) => {
        if (day.id === dayId) {
            return { ...day, showDropdown: !day.showDropdown };
        }
        return day;
    });

    setDefaultTimeSlots(updatedTimeSlots);
    };
    const generateTimeSlots = (duration) => {
    const timeSlots = [];
    const endTime = 23 * 60 + 59; // Convert end time to minutes
    duration = parseInt(duration);   
    for (let totalMinutes = 0; totalMinutes <= endTime; totalMinutes += duration) {
        const hour = Math.floor(totalMinutes / 60);
        const minute = totalMinutes % 60;
        
        const formattedHour = String(hour).padStart(2, '0');
        const formattedMinute = String(minute).padStart(2, '0');
        
        timeSlots.push(`${formattedHour}:${formattedMinute}`);
    }

    
            
    return timeSlots;
    };
    const isSlotDisabled = (slot, dayID) => {
    for (const day of defaultTimeSlots) {
        if (dayID == day.id) {
        for (const existingSlot of day.slots) {
        const existingTime = existingSlot.time < 10 ? `0${existingSlot.time}` : existingSlot.time;
        if (existingTime === slot || `${existingTime}:00` === slot) {
            return true;
        }
        }
    }
    }
    return false;
    };
    function handleContentfun(e) {
    setContent_File(e.target.files[0])
    setContentText(e.target.files[0]?.name)
    setRemoveopop(true)
    setUrlPop(false)
    }
    function handleRemoveUrl() {
        setContentText(null);
        setContent_File(null);
        setRemoveopop(false);
        setUrlPop(true);
    }
    const handleDropdownSelect = (dayId, selectedTime) => {
      const updatedTimeSlots = defaultTimeSlots.map((day) => {
          if (day.id === dayId) {
              const newSlotId = `${day.id}-${day.slots.length}`;
              const newSlot = {
                  id: newSlotId,
                  time: selectedTime,
              };
              const updatedSlots = [...day.slots, newSlot];
              return { ...day, slots: updatedSlots, showDropdown: false };
          }
          return day;
      });

      setDefaultTimeSlots(updatedTimeSlots);
    };
    const updateContent = (e) => {
      let obj = {content}
      if (content == 'calender') {
        if ((meeting_location == 'google' && !isMeetConnected.google_meet) || (meeting_location == 'zoom' && !isMeetConnected.zoom)) {
          setShowConnectCalendarModal(true);
          return false;
        }
        obj = {
          ...obj,
          duration,
          book_within_next_day,
          custom_meeting_link,
          timezone,
          meeting_location,
          monTimeSlot: JSON.stringify(defaultTimeSlots[1].slots),
          tueTimeSlot: JSON.stringify(defaultTimeSlots[2].slots),
          wedTimeSlot: JSON.stringify(defaultTimeSlots[3].slots),
          thurTimeSlot: JSON.stringify(defaultTimeSlots[4].slots),
          friTimeSlot: JSON.stringify(defaultTimeSlots[5].slots),
          satTimeSlot: JSON.stringify(defaultTimeSlots[6].slots),
          sunTimeSlot: JSON.stringify(defaultTimeSlots[0].slots),
        }
      }else if(content == 'files'){
        obj = {
          ...obj,
          content_file,
          contentText,
        }
      }else if(content == 'url'){
        obj = {
          ...obj,
          content_url,
        }
      }
      handleSubmit(e,obj,'content')
    }
    useEffect(() => {
      const handleMessage = (event) => {
  
        if (event.data && event.data.type === 'meetToken') {
  
          setIsMeetConnected(!!event.data.meetToken);
          setShowConnectCalendarModal(false);
        }
      };
      window.addEventListener('message', handleMessage);
    
      return () => {
        window.removeEventListener('message', handleMessage);
      };
    }, []);
    useEffect(()=>{
      setRemoveopop(data?.content_file_name ? true : false);
      setUrlPop(data?.content_file_name ? false : true);
    },[data])
    return (
        <div class="tab-pane fade customize-page-main active show p20-u" id="customizePageSocials" role="tabpanel" aria-labelledby="social-tab">
            <h4 class="productHead1">Product Content</h4>
                <form id="newProductModal" className="ProductBody" name="product_type" method="post" role="form">
                    <div className="notification-container"></div>
                    <div class="form-group mt-20px">
                                  <label className="productlabels">
                                    Product Content
                                  </label>
                                  <div class="up-in-toggle p-1 bg-f1f1f1 producttoggle mt-10px">
                                    <input
                                      checked={content == "calender" ? true :false}
                                      type="radio"
                                      id="calendar"
                                      name="content"
                                      value="calender"
                                      onChange={(a) => {
                                        setContent(a.target.value);
                                        setOpenCalendar(true);
                                        setOpendigitalfile(false);
                                      }}
                                      style={{
                                        display: "none",
                                      }}
                                    />
                                    <label
                                      onClick={handleCalendar}
                                      htmlFor="calendar"
                                      className="d-flex align-items-center justify-content-center"
                                      style={{
                                        padding: "9px",
                                        borderRadius: "9px",
                                        fontSize: 18,
                                        display: "flex",
                                        justifyContent: "center"
                                      }}
                                    >
                                      <img
                                        className="me-2 content-img"
                                        width="20px"
                                        src={calendarIcon}
                                      />
                                      <span className="productlabels">
                                        Calendar
                                      </span>
                                    </label>
                                    <input
                                      checked={content == "files"? true :false}
                                      type="radio"
                                      id="digitalFile"
                                      name="content"
                                      value={"files"}
                                      onChange={(a) => {
                                        setContent(a.target.value);
                                        setOpenCalendar(false);
                                        setOpendigitalfile(true);
                                      }}
                                      style={{
                                        display: "none",
                                      }}
                                    />
                                    <label
                                      onClick={handldigital}
                                      htmlFor="digitalFile"
                                      style={{
                                        padding: "9px",
                                        borderRadius: "9px",
                                        fontSize: 18,
                                        display: "flex",
                                        justifyContent: "center"
                                      }}
                                    >
                                      <img
                                        className="me-2 content-img"
                                        width="20px"
                                        src={digitalFileIcon}
                                      />
                                      <span className="productlabels">
                                        Digital Files
                                      </span>
                                    </label>
                                    <input
                                      checked={content == "url"? true : false}
                                      type="radio"
                                      id="visitUrl"
                                      name="content"
                                      value={"url"}
                                      onClick={(a) =>
                                        setContent(a.target.value)
                                      }
                                      style={{
                                        display: "none",
                                      }}
                                    />
                                    <label
                                      onClick={handlevisiturl}
                                      htmlFor="visitUrl"
                                      style={{
                                        padding: "9px",
                                        borderRadius: "9px",
                                        fontSize: 18,
                                        display: "flex",
                                        justifyContent: "center"
                                      }}
                                    >
                                      <img
                                        className="me-2 content-img"
                                        width="20px"
                                        src={visitIcon}
                                      />
                                      <span className="productlabels">
                                        Visit URL
                                      </span>
                                    </label>
                                  </div>
                                  {openCalendar && (
                                    <>
                                      <div style={{ marginTop: 16 }}>
                                        <p style={{ fontSize: 13 }}>
                                        Let customers book a time slot on your calendar. For paid coaching,<br /> meetings and events.
                                        {" "}
                                        </p>
                                      </div>
                                      <div className="mb-4">
                                        <p className="mt-4 mb-1 input-text fs-14px">
                                          Meeting Details
                                        </p>
                                        <div className=" d-flex">
                                          <div className="break-between fs-14px">
                                          <span className="text-gray ">Duration</span>
                                            <select name="cars" id="cars" value={duration} onChange={(a) => {setDuration(a.target.value ) } } >
                                              <option value="15">15 min</option>
                                              <option value="30">30 min</option>
                                              <option value="45">45 min</option>
                                              <option value="60">60 min</option>
                                            </select>
                                          </div>
                                          <div className="break-between fs-14px"style={{ marginLeft: "2vh" }}>
                                          <span className="text-gray ">Within the next</span>
                                            <select
                                              name="cars" id="cars" value={book_within_next_day} onChange={(a) =>setBook_Within_Next_Day(a.target.value)}>
                                              <option value="7">7 days</option>
                                              <option value="14">14 days</option>
                                              <option value="30">30 days</option>
                                              <option value="60">60 days</option>
                                            </select>
                                          </div>
                                        </div>
                                        <div className="timezone-select-container fs-14px">
                                        <div
                                          className="timezone-select-input-container"
                                          onClick={() => setDropdownOpen(!dropdownOpen)}
                                        >
                                          <img src={globeIcon} alt="" width="20px" className="globe-icon" />
                                          <span className="text-grey">Timezone</span>
                                          <div className="selected-timezone ">
                                            {selectedTimezone ? (
                                              <>
                                              <span>{selectedTimezone.city ? 'GMT' : selectedTimezone  } </span>&nbsp;
                                                <div className="selected-timezone-city">
                                                  {selectedTimezone.city ? selectedTimezone.gmtOffset +' '+ selectedTimezone.city : null}
                                                </div>
                                                <div className="selected-timezone-time">
                                                  {selectedTimezone.formattedTime}
                                                </div>
                                              </>
                                            ) : (
                                              <div className="selected-timezone-placeholder"><span>GMT+0</span>&nbsp; Select timezone </div>
                                            )}
                                            <img src={chevron_down} width="15px" alt="" style={{ paddingLeft: 5, paddingTop: 2 }} />
                                          </div>

                                        </div>
                                        {dropdownOpen && (
                                          <div >
                                            <div className="timezone-dropdown"style={{ overflowY: "auto", overflowX: "hidden", height: 256 }}>
                                              <div className="bg-input ">
                                                <img src={searchicon} alt="" width="20px" />
                                                <input
                                                  style={{ backgroundColor: "transparent", border: "none", outline: "none" }}
                                                  type="text"
                                                  placeholder="Search"
                                                  onChange={handleSearchInputChange}
                                                  className="timezone-search-bar "
                                                />
                                              </div>
                                              <div  >
                                                {filteredTimezones.map((timezone) => (

                                                  <div
                                                    key={timezone.timezone}
                                                    className="timezone-option"
                                                    onClick={() => handleSelectTimezone(timezone)}
                                                  >
                                                    <div className="timezone-option-city">{timezone.city}</div>
                                                    <div className="timezone-option-time">
                                                      {timezone.gmtOffset}
                                                    </div>
                                                  </div>
                                                ))}
                                              </div>
                                            </div>
                                          </div>
                                        )}

                                      </div>
                                        </div>
                                      <div>
                                        <p style={{ fontSize: 14 }}>Your Availability</p>
                                        <div>
                                        <Tabs defaultActiveKey="0"
                                            className="mb-3 up-in-toggle main-btn-header btnTxtDark border-less"
                                            justify
                                            id="days-tabs">
                                              {defaultTimeSlots.map((day) => ( 
                                                  <Tab eventKey={day.id} title={day.day} key={day.id}>
                                                      <div className="d-flex justify-content-start flex-wrap">
                                                          {day.slots.map((slot) => (
                                                             slot.time ? (
                                                              <div className="px-1" key={slot.id}>
                                                                  <div className="form-group">
                                                                      <label className="text-gray font-weight-500"></label>
                                                                      <div className="time-slot-btn">
                                                                      {formatTime(slot.time)}
                                                                      {/*  (String(slot.time).includes(':30') || String(slot.time).includes(':00') || String(slot.time).includes(':15') || String(slot.time).includes(':45') ? String(slot.time) : String(slot.time).padStart(2, '0') + ':00') */}
                                                                          <small
                                                                              className="badge  text-dark p-1"
                                                                              onClick={() => handleDeleteTimeSlot(day.id, slot.id)}
                                                                          >
                                                                              <img src={dustbinWhite} alt="" />
                                                                          </small>
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                              ) : null
                                                          ))}
                                                      </div>
                                                      <div className="mt-3 position-relative">
                                                        <button type="button" className="add-question-btn" onClick={() => handleNewAddTimeSlot(day.id)}>
                                                            <span className="m-0">Add time slot</span>
                                                            <img src={addFaqIcon} width="18px" alt="" />
                                                        </button>
                                                        {day.showDropdown && (
                                                          <div className="availability-dropdown z-index-3">
                                                             {generateTimeSlots(duration).map((slot) => (
                                                              <div
                                                                key={slot}
                                                                className={`${isSlotDisabled(slot, day.id) ? 'availability-option-check' : 'availability-option'}`}
                                                                onClick={isSlotDisabled(slot, day.id) ? null : () => handleDropdownSelect(day.id, slot)}
                                                              >
                                                                {slot}
                                                              </div>
                                                            ))}
                                                          </div>
                                                            // <select className="form-select" onChange={(e) => handleDropdownSelect(day.id, e.target.value)}>
                                                            //     <option disabled selected>Select a time</option>
                                                            //     <option value="9">9:00</option>
                                                            //     <option value="9:30">9:30</option>
                                                            //     <option value="10">10:00</option>
                                                            //     <option value="10:30">10:30</option>
                                                            // </select>
                                                        )}
                                                    </div>
                                                  </Tab>
                                              ))}
                                          </Tabs>
                                        </div>
                                      </div>
                                      <div
                                        style={{ marginTop: 20 }}
                                      >

                                        <div>
                                          <div class="form-group parent-producttoggle mt-4">
                                            <p className="m-1 fs-14px">Meeting Location</p>
                                            <div class="up-in-toggle bg-f1f1f1 mian-google-style ">
                                              <input

                                                checked={meeting_location == "google" ? true : false}
                                                type="radio"
                                                id="googlemeet"
                                                name="meeting_location"
                                                value={"google"}
                                                onChange={(a) =>
                                                  setMeetingLocation(a.target.value)
                                                }
                                                style={{
                                                  display: "none",
                                                }}
                                              />
                                              <label
                                                htmlFor="googlemeet"
                                                style={{
                                                  padding: "10px",
                                                  borderRadius: "10px",
                                                  fontSize: 17,
                                                  whiteSpace: "nowrap",
                                                  fontWeight: 500,
                                                  color: "#5B5B5B",



                                                }}
                                              >
                                                <img
                                                  style={{ marginRight: 3 }}
                                                  width="11%"
                                                  src={googlemetIcon}
                                                />
                                                Google Meet
                                              </label>
                                              <input

                                                checked={meeting_location == "custom" ? true : false}
                                                type="radio"
                                                id="custom"
                                                name="meeting_location"
                                                value={"custom"}
                                                onChange={(a) =>
                                                  setMeetingLocation(a.target.value)
                                                }
                                                style={{
                                                  display: "none",
                                                }}
                                              />
                                              <label
                                                htmlFor="custom"
                                                style={{
                                                  padding: "10px",
                                                  borderRadius: "10px",
                                                  fontSize: 17,
                                                  whiteSpace: "nowrap",
                                                  fontWeight: 500,
                                                  color: "#5B5B5B",

                                                }}
                                              >
                                                <img
                                                  style={{ marginRight: 3 }}
                                                  width="12%"
                                                  src={customIcon}
                                                />
                                                Custom
                                              </label>
                                              <input

                                                checked={meeting_location == "zoom" ? true : false}
                                                type="radio"
                                                id="zoom"
                                                name="meeting_location"
                                                value={"zoom"}
                                                onChange={(a) =>
                                                  setMeetingLocation(a.target.value)
                                                }
                                                style={{
                                                  display: "none",
                                                }}
                                              />
                                              <label
                                                htmlFor="zoom"
                                                style={{
                                                  padding: "10px",
                                                  borderRadius: "10px",
                                                  fontSize: 12,
                                                  whiteSpace: "nowrap",
                                                  fontWeight: 500,
                                                  color: "#5B5B5B",
                                                }}
                                              >
                                                <img
                                                  style={{ marginRight: 3 }}
                                                  width="12px"
                                                  src={zoomIcon}
                                                />
                                                Zoom
                                              </label>
                                            </div>
                                            {meeting_location == 'custom'?
                                            <>
                                            <p className="mt-2">
                                              <img
                                                src={questionIcon}
                                                width="28px"
                                                style={{ padding: 5 }}
                                              />
                                              Create the meeting yourself after a customer purchases
                                            </p>
                                            <div className="mt-3">
                                            <input
                                              value={custom_meeting_link}
                                              onChange={(a) =>
                                                setCustomMeetingLink(a.target.value)
                                              }
                                              type="url"
                                              className="zaapGlobal-input"
                                              name="short_description"
                                              placeholder="Meeting Service URL"
                                            />
                                            </div>
                                            </>
                                            : null}
                                            {meeting_location == 'google'?
                                            <p className="ms-1 mt-1">Google Meet event will be created automatically after booking. Manage Google Account from settings
                                            </p>:null}
                                          </div>
                                        </div>
                                      </div>



                                    </>
                                  )}
                                  {opendigitalfile && (

                                    <>
                                      {urlPop && <div
                                        className="form-group w-100 product-files-section"
                                        data-type="files"
                                      >

                                        <label className="text-gray font-weight-500 mt-3 mb-0 productlabel3">
                                            Upload your Product files, your
                                            customers will receive an email with
                                            a download link.
                                        </label>
                                        <div className="dropzone-wrapper dropzone-wrapper4 product-dropzone productnewdropzone">
                                          <div className="dropzone-desc">
                                            <img src={imageIcon} alt="" />
                                            <h6 className="mt-2 mb-1 text-gray">
                                              Drag and drop files, or{" "}
                                              <a className="text-primary decoration-none">
                                                click here to browse
                                              </a>
                                            </h6>
                                            <small className="text-muted-light">
                                              100MB max file size upload
                                            </small>
                                          </div>
                                          <input
                                            onChange={handleContentfun}
                                            type="file"
                                            accept=".png,.jpg,.jpeg,.gif,.bmp,.pdf,.doc,.docx,.ppt,.pptx,.xls,.xlsx,.zip,.rar,.svg"
                                            name="content_file"
                                            className="dropzone dropzone4"
                                          />
                                        </div>
                                      </div>}

                                      {removepop && <div style={{ display: "flex", justifyContent: "space-between", marginTop: 10 }}>
                                        <p>{contentText}</p>
                                        <img src={removeicon} width="3%" style={{ cursor: "pointer" }} onClick={handleRemoveUrl} />
                                      </div>}

                                    </>
                                  )}

                                  {openVisitUrl && (
                                    <div>
                                      <div
                                        className="form-group mt-2 w-100 product-files-section"
                                        data-type="url"
                                      >
                                        <label className="text-gray font-weight-500 mt-3 mb-2">
                                          <small>
                                            Redirect to a URL after purchase
                                            <small className="text-red">
                                              *
                                            </small>
                                          </small>
                                        </label>
                                        <input
                                          type="url"
                                          className="zaapGlobal-input"
                                          name="content_url"
                                          placeholder="Please Enter Redirect URL"
                                          onChange={(a) => setContent_Url(a.target.value)}
                                          value={content_url}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>

                    
                    <div class="text-center mt-4 d-flex submit-buttons-fixed" style={{zIndex:15}}>
                        <button type="button" onClick={(e) => updateContent(e)} class="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center">
                            <b class="me-2 productlabel2">Save Changes</b>
                            <small class="badge bg-primary-dark text-dark px-2 py-1 d-flex align-items-center justify-content-center">
                                <img src={returnIcon} alt="" />
                            </small>
                        </button>
                    </div>
                </form>
                {showConnectCalendarModal && <ConnectCalendarModal show={showConnectCalendarModal} setShow={setShowConnectCalendarModal} text={<>Connect your {meeting_location == 'zoom' ? 'Zoom' : 'Google'} Account to create Meet <br/> invite links when a customer purchases.</>} TwoButtons={true}/>}
        </div >
    )
}
export default ContentPage